const config = {
  "apigateway_key": "SjNIWW1ONkV4YXI2NW1wN1VpWHRQUUxUeQ==",
  "version": "1.3.250110",
  "application_name": "Liquid",
  "serverUrl": "https://qa.fct.telcotech.co:8095",
  "menuExclusionsList": [
    "/authentication/login"
    , "/authentication/reset-password"
  ],
  "authentication": {
    "defaultUserRegistrationGroup": "1"
  },
  "refresh_interval": 30000
}

export { config }
//http://13.245.156.220:8082
//"serverUrl": "http://13.245.156.220:8094",
//"serverUrl": "https://geographix.telcotech.co:8081",